import React, { useEffect, useState } from 'react';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import Header from '../components/global/header';
import SideMenu from '../components/global/sideMenu';

import MyTeam from '../components/myTeam/MyTeam';
import Players from '../components/players/Players';
const Admin = (props) => {

	const [headerTitle,                   setHeaderTitle]                      = useState('');
    const [menuID,                        setMenuID]                           = useState(null);

    const toggleState = (title, menuID) => {
		setHeaderTitle(title);
        setMenuID(menuID);
	};

    return (  
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <SideMenu menuID={menuID} />
            <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">
                
                <Header onLogout={props.onAuthStateChange} title={headerTitle} /> 
                <div style={{ paddingTop: '0px' }} id="kt_content" className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor padding-b0">
                    <Switch>
                        <Route path='/myTeam' exact>
                            <MyTeam
                                title={toggleState} 
                            />
                        </Route>
                        <Route path='/players/:typeOfPlayer'>
                            <Players
                                title={toggleState} 
                            />
                        </Route>
                        <Redirect to={'/myTeam'} />
                    
                    </Switch>
                </div>
            </div>
            
        </div>
    );
};

export default withRouter(Admin);