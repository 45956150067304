import React, { useState, useEffect } from 'react'
import * as helper from '../global/helper';
import Loading from '../global/loading';
import { getCategory, getTeam } from '../api/playerAPI';
import Select from 'react-select';

const PlayerTable = ({
    from,
    playerList,
    loading
}) => {

    const [filteredPlayerList,      setFilteredPlayerList]      = useState([])
    const [searchString,            setSearchString]            = useState('');
    const [category,                setCategory]                = useState([]);
    const [selectedCategory,        setSelectedCategory]        = useState(0);
    const [filterTeam,              setFilterTeam]              = useState(null);
    const [allTeamList,             setAllTeamList]             = useState([]);

    useEffect(() => {
        fetchCategory();
        fetchTeam();
    }, [])

    useEffect(() => {
        if (playerList.length > 0)
            filterPlayer();
        else
            setFilteredPlayerList([]);
            
    }, [playerList, searchString, selectedCategory, filterTeam]);

    
    const filterPlayer = async () => {

        let reports = playerList;
        if (selectedCategory && selectedCategory != 0) reports = reports.filter(f => f.CategoryID == selectedCategory);
        if (filterTeam && filterTeam.ID != 0) reports = reports.filter(f => f.TeamID == filterTeam.ID)
        if (searchString === '') {
            setFilteredPlayerList(reports);
        }else{
            const matchedPolicies = reports.filter((p) => {
                let matchString = `${p.FirstName} ${p.LastName} ${p.MemberShipNo} ${p.CategoryName} ${p.Email}`;
                let matchFound = 0;
                const searchArr = searchString.split(' ');
                searchArr.forEach((term) => {
                    matchFound += matchString.toLowerCase().includes(term.toLowerCase())
                        ? 1
                        : 0;
                });
                return matchFound === searchArr.length;
            });
            setFilteredPlayerList(matchedPolicies);
        }
    };

    const fetchCategory = async() => {
        try {
            const response = await getCategory()
            setCategory(response);             
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    const fetchTeam = async () => {
        try {
            const response = await getTeam();
            const teams = [{ID: 0, Name: 'All', DisplayName: 'All'}, ...response]
            setFilterTeam(teams[0])
            setAllTeamList(teams)          
        } catch (error) {
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <>
            <div className="kt-form kt-form--label-right">
                <div className="row align-items-center stickyFilter">
                    <div className="col-xl-12 order-2 order-xl-1">
                        <div className="row">
                            <div className={`col-md-3`}>
                                    <select 
                                        className="form-control kt-selectpicker"
                                        value={selectedCategory || ''}
                                        onChange={(e) => setSelectedCategory(e.target.value)}
                                    >
                                        {(category && category.length > 0) && (
                                            category.map(c => (
                                                <option key={c.ID} value={c.ID}>{c.Name}</option>
                                            ))
                                        )}
                                    </select>
                            </div>
                            {from == 'sold' && (
                                <div className={`col-md-3`}>
                                    <Select
                                        value={filterTeam && {label: filterTeam.DisplayName, value: filterTeam}}
                                        onChange={(event) => setFilterTeam(event.value)}
                                        name="ddlTeam"
                                        placeholder="Select Team"
                                        id="ddlTeam"
                                        className="ddlTeam"
                                        options={allTeamList.map(c => ({
                                            label: `${c.DisplayName}`,
                                            value: c
                                        }))}
                                    />
                                </div>
                            )}
                            <div className={`col-md-3 kt-margin-b-20-tablet-and-mobile`}>
                                <div className="kt-input-icon kt-input-icon--left">
                                    <input                                      
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Player..."
                                        id="generalSearch"
                                        value={searchString || ''}
                                        onChange={(e) => setSearchString(e.target.value)}
                                        >
                                    </input>
                                    <span className="kt-input-icon__icon kt-input-icon__icon--left">
                                        <span>
                                            <i className="la la-search"></i>
                                        </span>
                                    </span>
                                    {searchString && (
                                        <span className="kt-input-icon__icon kt-input-icon__icon--right">
                                            <span>
                                                <i className="la la-close" onClick={() => setSearchString('')}></i>
                                            </span>
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading ? (<Loading/>) : (
                    filteredPlayerList.length > 0 ? (
                        <>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded margin-b0">
                                        <table className="kt-datatable__table">
                                            <thead
                                                className="kt-datatable__head"
                                                style={{ display: 'block' }}>
                                                <tr className="kt-datatable__row block-row">
                                                    <th className="kt-datatable__cell text-clip" width={`${from == 'sold' ? '25%' : '40%'}`}>
                                                        <span className="padding-l10">Player</span>
                                                    </th>
                                                    {from == 'sold' && 
                                                        <>
                                                            <th className="kt-datatable__cell text-clip" width="25%">
                                                                <span className="padding-l10">Team</span>
                                                            </th>
                                                            <th className="kt-datatable__cell text-clip" width="20%">
                                                                <span className="padding-l10">Amount</span>
                                                            </th>
                                                        </>
                                                    }
                                                    <th className="kt-datatable__cell text-clip" width={`${from == 'sold' ? '15%' : '30%'}`}>
                                                        <span>Membership No.</span>
                                                    </th>
                                                    <th className="kt-datatable__cell text-clip" width={`${from == 'sold' ? '15%' : '30%'}`}>
                                                        <span>Category</span>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <div 
                                        className="kt-datatable kt-datatable--default kt-datatable--brand kt-datatable--scroll kt-datatable--loaded scrolTable"
                                        onScroll={helper.setStickyHeader}>
                                        <table className="kt-datatable__table table-striped">
                                            <tbody
                                                className="kt-datatable__body"
                                                style={{ display: 'block', minHeight:'40vh'}}>
                                                {filteredPlayerList.map((p, i) => (
                                                    <tr
                                                        key={i}
                                                        className="kt-datatable__row block-row">                                                   
                                                        <td width={`${from == 'sold' ? '25%' : '40%'}`} className="kt-datatable__cell text-clip">
                                                            <div className="kt-user-card-v2">
                                                                <div className="kt-user-card-v2__pic">
                                                                    {p.Photo && p.Photo !== '' ? (
                                                                        <img src={p.Photo} height="40" width="40" alt="banner-image" />
                                                                    ) : (
                                                                        <div className="kt-badge kt-badge--xl kt-badge--info">
                                                                            {p.DisplayName.substring(0, 1)}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                                <div className="kt-user-card-v2__details text-clip">
                                                                    <span className="kt-user-card-v2__name text-clip">{p.DisplayName}</span>
                                                                    <span className="kt-user-card-v2__desc text-clip">Handicap: {p.Handicap}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {from == 'sold' &&
                                                            <>
                                                                <td width='25%' className="kt-datatable__cell text-clip">
                                                                    <div className="kt-user-card-v2">
                                                                        <div className="kt-user-card-v2__pic">
                                                                            {(p.Logo && p.Logo != '') ? <img src={p.Logo} height="40" width="40" alt="banner-image"/> :  <div className="kt-badge kt-badge--xl kt-badge--success">{p.TeamName.substring(0, 1)}</div>}
                                                                        </div>
                                                                        <div className="kt-user-card-v2__details text-clip">
                                                                            <span className="kt-user-card-v2__name text-clip">{p.TeamName}</span>
                                                                        </div>
                                                                    </div>      
                                                                </td>
                                                                <td width='20%' className="kt-datatable__cell text-clip">
                                                                    <i className="la la-rupee"></i>
                                                                    {p.Amount}
                                                                </td>
                                                            </>
                                                        }
                                                        <td width={`${from == 'sold' ? '15%' : '30%'}`} className="kt-datatable__cell text-clip">{p.MemberShipNo}</td>
                                                        <td width={`${from == 'sold' ? '15%' : '30%'}`} className="text-clip">
                                                            <span className={`kt-badge kt-badge--${p.CategoryName == 'Gold' ? 'gold' : p.CategoryName == 'Silver' ? 'silver' :'bronze'} kt-badge--inline kt-badge--pill kt-badge-pill-width`}>
                                                                {p.CategoryName}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="padding-20">
                            <div className="empty-image-container">
                                <h5 className="margin-t20">No Player Found</h5>
                            </div>
                        </div>
                    )
                )}
            </div>
        </>
    )
}

export default PlayerTable