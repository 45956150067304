import React, { useEffect, useState } from 'react'
import swal from 'sweetalert2';
import { getUnAssignedPlayers } from '../api/playerAPI';
import PlayerTable from './PlayerTable';

const UnAssignedPlayer = () => {

    const [playerList,          setPlayerList]          = useState([]);
    const [loading,             setLoading]             = useState(false);

    useEffect(() => {
        fetchUnAssignedPlayers();
    }, [])

    const fetchUnAssignedPlayers = async () => {
        try {
            setLoading(true);
            const players = await getUnAssignedPlayers();
            setPlayerList(players);             
            setLoading(false);
        } catch (error) {
            setLoading(false);
            swal.fire({
                icon      : 'error',
                titleText : error.message
            })  
        }
    }

    return (
        <>
            <PlayerTable
                from='unassigned'
                playerList={playerList}
                loading={loading}
            />
        </>
    )
}

export default UnAssignedPlayer