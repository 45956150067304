import React, { useEffect, useState } from 'react'
import { getUnAssignedPlayers } from '../api/playerAPI';
import swal from 'sweetalert2';
import { NavLink, useParams, useHistory } from 'react-router-dom'
import UnAssignedPlayer from './UnAssignedPlayer';
import UnsoldPlayer from './UnsoldPlayer';
import SoldPlayer from './SoldPlayer';

const Players = (props) => {

    const param = useParams();

    useEffect(() => {
        props.title("Players", "menuPlayers");
    }, [])

    return (
        <>
            <div className="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid margin-t20">
                <div className="kt-portlet kt-portlet--mobile" >
                    <div className="kt-portlet__head kt-portlet__head--lg padding-l0">
                        <div className="kt-portlet__head-label">
                            <nav className="file-nav navbar navbar-top" >
                                <NavLink activeClassName="top-nav-active" to="/players/unassigned" >Upcoming Players</NavLink>
                                <NavLink activeClassName="top-nav-active" to="/players/sold" >Sold Players</NavLink>
                                <NavLink activeClassName="top-nav-active" to="/players/unsold" >Unsold Players</NavLink>
                            </nav>
                        </div>      
                    </div>
                    <div className="kt-portlet__body kt-portlet__body--fit">
                        {param.typeOfPlayer === 'unassigned' && (
                            <UnAssignedPlayer
                            />
                        )}
                        {param.typeOfPlayer === 'sold' && (
                            <SoldPlayer
                            />
                        )}
                        {param.typeOfPlayer === 'unsold' && (
                            <UnsoldPlayer
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Players