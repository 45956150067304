import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/styles/dark.css';
import BannerLogoLight from '../../assets/images/PGL_Banner.png';
import LogoLight from '../../assets/images/PGL_Icon.png';
import swal from 'sweetalert2';
import * as helper from '../global/helper';
import { useHistory } from 'react-router-dom';

const sideMenu = (props) => {

    const [menuList,        setMenuList]       = useState([]);
    const [subMenuList,     setSubMenuList]    = useState([]);
    const history 							   = useHistory();

    useEffect(() => {
        $('.kt-menu__item').removeClass('kt-menu__item--active');
        $('#' + props.menuID).addClass('kt-menu__item--active');
    
    });

    const toggleTitle = (e) => {
        e.preventDefault()
        if ($('body').hasClass('kt-aside--minimize')) {
          $('#menuMyTeam .kt-menu__link-icon .kt-svg-icon').attr(
            'data-original-title',
            'My Team'
          );
          $('#menuPlayers .kt-menu__link-icon .kt-svg-icon').attr(
            'data-original-title',
            'Players'
          );
          $('.kt-aside__brand-tools').addClass('aside-brand-tools');
          $('#asideBrandLogo').attr('src', LogoLight);
          $('#asideBrandLogo').attr('height', '30');
        } else {
          $('.kt-menu__link-icon .kt-svg-icon').removeAttr('data-original-title');
          $('.kt-aside__brand-tools').removeClass('aside-brand-tools');
          $('#asideBrandLogo').attr('src', BannerLogoLight);
          $('#asideBrandLogo').attr('height', '35');
        }
      };
    

    return (
        <div className="kt-aside kt-aside--fixed  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop" id="kt_aside">
            <div className="kt-aside__brand kt-grid__item " id="kt_aside_brand">
                <div className="kt-aside__brand-logo">
                    <NavLink disabled={props.disabled} to="/">
                        <img id="asideBrandLogo" alt="Logo" src={BannerLogoLight} height="35"/>
                    </NavLink>
                </div>
                <div className="kt-aside__brand-tools">
                    <button
                        onClick={(e) => toggleTitle(e)}
                        className="kt-aside__brand-aside-toggler"
                        id="kt_aside_toggler">    
                        <span>
                            <svg className="nav-movment" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M13.706 9.698a.988.988 0 0 0 0-1.407 1.01 1.01 0 0 0-1.419 0l-2.965 2.94a1.09 1.09 0 0 0 0 1.548l2.955 2.93a1.01 1.01 0 0 0 1.42 0 .988.988 0 0 0 0-1.407l-2.318-2.297 2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                        </span>
                        <span>
                            <svg className="nav-movment margin-l65" width="24" height="24" viewBox="0 0 24 24" focusable="false" role="presentation"><path d="M10.294 9.698a.988.988 0 0 1 0-1.407 1.01 1.01 0 0 1 1.419 0l2.965 2.94a1.09 1.09 0 0 1 0 1.548l-2.955 2.93a1.01 1.01 0 0 1-1.42 0 .988.988 0 0 1 0-1.407l2.318-2.297-2.327-2.307z" fill="currentColor" fillRule="evenodd"></path></svg>
                        </span>
                    </button>
                </div>
            </div>
            <div className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid" id="kt_aside_menu_wrapper">
                <div id="kt_aside_menu" className="kt-aside-menu kt-scroll margin-t0" data-ktmenu-vertical="1" data-ktmenu-scroll="1" data-ktmenu-dropdown-timeout="500" style={{ marginTop: '0px', height: '835px', overflow: 'auto' }}>
                    <ul style={{ paddingTop: '0px' }} className="kt-menu__nav ">
                        <li id="menuMyTeam" className="kt-menu__item kt-menu__item--active" aria-haspopup="true">          
                            <NavLink disabled={props.disabled} to="/myTeam" className="kt-menu__link ">
                                <span data-original-title="Policy Stats" className="kt-menu__link-icon">
                                    <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <rect fill="#000000" opacity="0.3" x="13" y="4" width="3" height="16" rx="1.5"></rect>
                                        <rect fill="#000000" x="8" y="9" width="3" height="11" rx="1.5"></rect>
                                        <rect fill="#000000" x="18" y="11" width="3" height="9" rx="1.5"></rect>
                                        <rect fill="#000000" x="3" y="13" width="3" height="7" rx="1.5"></rect>
                                        </g>
                                    </svg>
                                </span>
                                <span className="kt-menu__link-text">My Team</span>
                            </NavLink>
                        </li>
                        <li id="menuPlayers" className="kt-menu__item" aria-haspopup="true">
                        <NavLink disabled={props.disabled} to="/players/unassigned" className="kt-menu__link ">
                            <span className="kt-menu__link-icon">
                                <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" className="kt-svg-icon" data-toggle="kt-tooltip" data-placement="right">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <polygon points="0 0 24 0 24 24 0 24" />
                                    <path d="M18,14 C16.3431458,14 15,12.6568542 15,11 C15,9.34314575 16.3431458,8 18,8 C19.6568542,8 21,9.34314575 21,11 C21,12.6568542 19.6568542,14 18,14 Z M9,11 C6.790861,11 5,9.209139 5,7 C5,4.790861 6.790861,3 9,3 C11.209139,3 13,4.790861 13,7 C13,9.209139 11.209139,11 9,11 Z" fill="#000000" fillRule="nonzero" opacity="0.3"/>
                                    <path d="M17.6011961,15.0006174 C21.0077043,15.0378534 23.7891749,16.7601418 23.9984937,20.4 C24.0069246,20.5466056 23.9984937,21 23.4559499,21 L19.6,21 C19.6,18.7490654 18.8562935,16.6718327 17.6011961,15.0006174 Z M0.00065168429,20.1992055 C0.388258525,15.4265159 4.26191235,13 8.98334134,13 C13.7712164,13 17.7048837,15.2931929 17.9979143,20.2 C18.0095879,20.3954741 17.9979143,21 17.2466999,21 C13.541124,21 8.03472472,21 0.727502227,21 C0.476712155,21 -0.0204617505,20.45918 0.00065168429,20.1992055 Z" fill="#000000" fillRule="nonzero"/>
                                    </g>
                                </svg>
                            </span>
                            <span className="kt-menu__link-text">Players</span>
                        </NavLink>
                        </li>  
                    </ul>
                </div>
            </div>
        </div>
    );

};

export default sideMenu;


